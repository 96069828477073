import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { provideAnimations } from '@angular/platform-browser/animations';

export interface EnvObj {
  name: string;
  production: boolean;
  apiUrl: string;
  sentry_environment: string;
  sentry_enabled: boolean;
  shouldEnableProdMode: boolean;
  shouldEnableIntercom: boolean;
}

Sentry.init({
  enabled: environment.sentry_enabled,
  dsn: 'https://c335be0af428f6116ec3489206c51abd@o4507386448052224.ingest.us.sentry.io/4507584239894528',
  environment: environment.sentry_environment,
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [environment.apiUrl],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: 1.0, // Capture 100% of the profiles
});

if (environment.shouldEnableProdMode) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    providers: [
      provideAnimations()
    ]
  })
  .then(() => {
    // Fix for Angular Material Drag and Drop issue (https://github.com/angular/angular/issues/45020)
    document.addEventListener('mousemove', () => {}, {
      passive: false,
      capture: true,
    });
  })
  .catch(err => console.error(err));
