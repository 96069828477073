import { Pipe, PipeTransform } from '@angular/core';
import { LocationTaskCount } from '@root/services/location.service';

@Pipe({
  name: 'sidebarBadge',
  standalone: true,
})
export class SidebarBadgePipe implements PipeTransform {

  transform(locationID: number, locationCountMap: Map<number, LocationTaskCount>): LocationTaskCount {
    return locationCountMap.get(locationID);
  }

}
